// <!-- REACTIVITY API -->
import { ref } from 'vue';
import { useRouter } from 'vue-router';

/**
 * Create appropriate state and hooks for using tabs.
 * @param {{ tabs: SN.Tab[] | V.Ref<SN.Tab[]>}} state Local state to use.
 * @param {Router.Router} router Custom property hook.
 */
export const useTabDefs = (state, router = useRouter()) => {
    // Create reactive reference.
    const tabs = ref(state.tabs);

    /**
     * Check if this is the current tab.
     * @param {SN.Tab} tab Tab to check.
     */
    const isCurrentTab = (tab) => tab.to === router.currentRoute.value.path;

    // Prepare callback hook.
    const updateCurrentTabByRoute = () => {
        tabs.value = tabs.value.map((tab) => {
            tab.current = isCurrentTab(tab);
            return tab;
        });
    };

    // Expose reactive state and hooks.
    return {
        tabs,
        isCurrentTab,
        updateCurrentTabByRoute,
    };
};
